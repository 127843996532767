var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          staticClass: "btn-create",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: { click: _vm.onAdd },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("b-collapse", { attrs: { visible: "", id: "collapse-1" } }, [
            _c(
              "div",
              { staticClass: "mt-2", attrs: { id: "collapse-1" } },
              [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.onSearch(_vm.filterName)
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      { staticClass: "filter" },
                      [
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "AGFRNAME",
                                  type: "text",
                                  label: "Nominativo",
                                  placeholder: "Inserisci un nominativo",
                                },
                                model: {
                                  value: _vm.filter.byCustom.title.value,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byCustom.title,
                                      "value",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byCustom.title.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "NINO",
                                  type: "text",
                                  label: "Codice Fiscale",
                                  placeholder: "Inserisci un codice fiscale",
                                },
                                model: {
                                  value: _vm.filter.byAttribute.NINO,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byAttribute,
                                      "NINO",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byAttribute.NINO",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-row", [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-input", {
                                attrs: {
                                  name: "CREG",
                                  type: "text",
                                  label: "Partita IVA",
                                  placeholder: "Inserisci una partita IVA",
                                },
                                model: {
                                  value: _vm.filter.byAttribute.CREG,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter.byAttribute,
                                      "CREG",
                                      $$v
                                    )
                                  },
                                  expression: "filter.byAttribute.CREG",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "CLI",
                                  label: "Cliente",
                                  options: _vm.opt_sup_cli,
                                  taggable: false,
                                  multiple: false,
                                  closeOnSelect: true,
                                },
                                model: {
                                  value: _vm.filter.byAttribute.CLI,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter.byAttribute, "CLI", $$v)
                                  },
                                  expression: "filter.byAttribute.CLI",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("base-select", {
                                attrs: {
                                  name: "SUP",
                                  label: "Fornitore",
                                  options: _vm.opt_sup_cli,
                                  taggable: false,
                                  multiple: false,
                                  closeOnSelect: true,
                                },
                                model: {
                                  value: _vm.filter.byAttribute.SUP,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter.byAttribute, "SUP", $$v)
                                  },
                                  expression: "filter.byAttribute.SUP",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-row", [
                          _c(
                            "div",
                            {
                              staticClass: "form-group col-md-3 align-self-end",
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle:collapse-1",
                                      arg: "collapse-1",
                                    },
                                  ],
                                  attrs: {
                                    type: "submit",
                                    variant: "lisaweb",
                                    size: "sm",
                                  },
                                },
                                [_vm._v("Cerca")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-reset",
                                  attrs: {
                                    type: "button",
                                    variant: "lisaweb",
                                    size: "sm",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClearFilter(_vm.filterName)
                                    },
                                  },
                                },
                                [_vm._v("Reset")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-button-group",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle:collapse-1-inner",
                                arg: "collapse-1-inner",
                              },
                            ],
                            staticClass: "my-2 filter-button-group",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "when-open" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "funnel",
                                    "font-scale": "1.5",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "when-closed" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "funnel",
                                    "font-scale": "1.5",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v("\n            Altri Filtri\n          "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-collapse",
                          {
                            staticClass: "mt-2",
                            attrs: { id: "collapse-1-inner" },
                          },
                          [
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: "PHON",
                                      type: "text",
                                      label: "Telefono",
                                      placeholder:
                                        "Inserisci un numero di telefono",
                                    },
                                    model: {
                                      value: _vm.filter.byAddressBook.PHON,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byAddressBook,
                                          "PHON",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byAddressBook.PHON",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: "CELL",
                                      type: "text",
                                      label: "Cellulare",
                                      placeholder:
                                        "Inserisci un numero di cellulare",
                                    },
                                    model: {
                                      value: _vm.filter.byAddressBook.CELL,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byAddressBook,
                                          "CELL",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byAddressBook.CELL",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: "FAX",
                                      type: "text",
                                      label: "Fax",
                                      placeholder: "Inserisci un fax",
                                    },
                                    model: {
                                      value: _vm.filter.byAddressBook.FAX,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byAddressBook,
                                          "FAX",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byAddressBook.FAX",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: "MAIL",
                                      type: "text",
                                      label: "Email",
                                      placeholder: "Inserisci una mail",
                                    },
                                    model: {
                                      value: _vm.filter.byAddressBook.MAIL,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byAddressBook,
                                          "MAIL",
                                          $$v
                                        )
                                      },
                                      expression: "filter.byAddressBook.MAIL",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      name: "PROVINCE",
                                      label: "Provincia",
                                      options: _vm.opt_provinces,
                                      taggable: false,
                                      multiple: false,
                                      closeOnSelect: true,
                                    },
                                    on: {
                                      select: _vm.onSelectProvince,
                                      remove: _vm.onRemoveProvince,
                                    },
                                    model: {
                                      value: _vm.filter.province,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filter, "province", $$v)
                                      },
                                      expression: "filter.province",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-select", {
                                    attrs: {
                                      name: "CITY",
                                      label: "Comune",
                                      options: _vm.opt_councils,
                                      taggable: false,
                                      multiple: false,
                                      closeOnSelect: true,
                                    },
                                    model: {
                                      value: _vm.filter.byCorrespondence.CITY,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byCorrespondence,
                                          "CITY",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter.byCorrespondence.CITY",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: "CAP",
                                      type: "text",
                                      label: "CAP",
                                      maxlength: "5",
                                      placeholder: "Inserisci un CAP",
                                    },
                                    model: {
                                      value: _vm.filter.byCorrespondence.ZIPC,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byCorrespondence,
                                          "ZIPC",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter.byCorrespondence.ZIPC",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-3" },
                                [
                                  _c("base-textarea", {
                                    attrs: {
                                      name: "ADDR",
                                      label: "Indirizzo",
                                      placeholder: "Inserisci un indirizzo",
                                    },
                                    model: {
                                      value: _vm.filter.byCorrespondence.ADDR,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filter.byCorrespondence,
                                          "ADDR",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "filter.byCorrespondence.ADDR",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.customFilters.length > 0,
                                    expression: "customFilters.length > 0",
                                  },
                                ],
                              },
                              [
                                _c("p", [_vm._v("Attributi personalizzati")]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.customFilters,
                                  function (element, index) {
                                    return _c(
                                      "b-row",
                                      { key: index },
                                      [
                                        _c("custom-filter", {
                                          attrs: { element: element },
                                          model: {
                                            value:
                                              _vm.filter.byAttribute[
                                                element.key
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.filter.byAttribute,
                                                element.key,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "filter.byAttribute[element.key]",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("table-agency-contacts-accounting", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              filterOn: {
                byRelations: [
                  "byCorrespondence",
                  "byAddressBook",
                  "byDocument",
                  "byTask",
                ],
              },
              onlyActions: ["edit", "destroy", "infomodal"],
            },
            on: {
              edit: _vm.onEdit,
              open_note: _vm.openNoteModal,
              open_document: _vm.openDocumentModal,
              destroy: _vm.onDestroy,
            },
          }),
          _vm._v(" "),
          _c("note-detail-modal", { attrs: { items: _vm.notes } }),
          _vm._v(" "),
          _c("document-detail-modal", { attrs: { items: _vm.documents } }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }